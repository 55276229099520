import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "my-2 px-10" }
const _hoisted_2 = { class: "flex space-x-4 mb-1" }
const _hoisted_3 = { class: "text-neutral-dark" }
const _hoisted_4 = { class: "font-bold" }
const _hoisted_5 = { class: "flex justify-center min-h-screen my-10" }
const _hoisted_6 = { class: "sm:mx-auto w-full max-w-6xl" }
const _hoisted_7 = { class: "flex flex-col lg:flex-row gap-2 pb-10 w-full" }
const _hoisted_8 = { class: "flex flex-col lg:w-3/4 gap-2" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { class: "flex flex-col lg:w-1/4 gap-2" }
const _hoisted_11 = {
  key: 0,
  class: "flex justify-center items-center py-4 gap-2"
}
const _hoisted_12 = {
  key: 1,
  class: "flex justify-center items-center py-4 gap-2"
}
const _hoisted_13 = {
  key: 0,
  class: "btn bg-red-primary hover:bg-red-600 text-white py-2 w-32 rounded-md"
}
const _hoisted_14 = {
  key: 2,
  class: "btn btn--green-primary hover:bg-green-600 text-white py-2 w-32 rounded-md"
}
const _hoisted_15 = {
  key: 3,
  class: "btn btn--green-primary px-4 py-2 disabled:bg-status-draft disabled:text-white text-md rounded-md"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ol", _hoisted_2, [
        _createElementVNode("li", _hoisted_3, [
          _createVNode(_component_router_link, { to: "/admin/account-registration" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.SYSTEM.account_registrations), 1)
            ]),
            _: 1
          })
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("li", null, ">", -1)),
        _createElementVNode("li", _hoisted_4, [
          _createVNode(_component_router_link, { to: `` }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($setup.SYSTEM.register_detail), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode($setup["AccountInformationForm"], {
              "initial-data": $setup.formData.AccountInformation,
              accountData: $setup.accountData,
              "onUpdate:accountData": _cache[0] || (_cache[0] = ($event: any) => (($setup.accountData) = $event)),
              isReadOnly: true
            }, null, 8, ["initial-data", "accountData"]),
            _createVNode($setup["CompanyInformationForm"], {
              "initial-data": $setup.formData.CompanyInformation,
              companyData: $setup.companyData,
              "onUpdate:companyData": _cache[1] || (_cache[1] = ($event: any) => (($setup.companyData) = $event)),
              isReadOnly: $setup.isReadOnly
            }, null, 8, ["initial-data", "companyData", "isReadOnly"]),
            (
                $setup.formData.AccountInformation.ClientType ===
                $setup.RESUITE_ROLE.SupplyChainPartner
              )
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode($setup["BuyerInformationForm"], {
                    "initial-data": $setup.formData.BuyerInformation,
                    buyerData: $setup.buyerData,
                    "onUpdate:buyerData": _cache[2] || (_cache[2] = ($event: any) => (($setup.buyerData) = $event)),
                    isReadOnly: $setup.isReadOnly
                  }, null, 8, ["initial-data", "buyerData", "isReadOnly"])
                ]))
              : _createCommentVNode("", true),
            _createVNode($setup["DocumentsInformationForm"], {
              documentData: $setup.documentData,
              "onUpdate:documentData": _cache[3] || (_cache[3] = ($event: any) => (($setup.documentData) = $event)),
              tenantId: $setup.tenantIdRoute
            }, null, 8, ["documentData", "tenantId"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode($setup["StatusRegisterClient"], {
              status: $setup.formData?.Status
            }, null, 8, ["status"])
          ])
        ]),
        ($setup.isAmend)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("button", {
                class: "btn bg-red-primary hover:bg-red-600 text-white py-2 w-32 rounded-md",
                onClick: _withModifiers($setup.handleCancelAmend, ["prevent"])
              }, " Cancel "),
              _cache[5] || (_cache[5] = _createElementVNode("button", { class: "btn btn--green-primary hover:bg-green-600 text-white py-2 w-32 rounded-md" }, " Confirm ", -1))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_12, [
              ($setup.formStatus === $setup.STATUS_REGISTER_CLIENT.Pending)
                ? (_openBlock(), _createElementBlock("button", _hoisted_13, " Reject "))
                : _createCommentVNode("", true),
              (
              $setup.formStatus === $setup.STATUS_REGISTER_CLIENT.InivitationSent ||
              $setup.formStatus === $setup.STATUS_REGISTER_CLIENT.Pending
            )
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "btn bg-white border border-neutral-black hover:bg-gray-100 rounded-md w-32 py-2",
                    onClick: _withModifiers($setup.handleAmend, ["prevent"])
                  }, " Amend "))
                : _createCommentVNode("", true),
              ($setup.formStatus === $setup.STATUS_REGISTER_CLIENT.Pending)
                ? (_openBlock(), _createElementBlock("button", _hoisted_14, " Approve "))
                : _createCommentVNode("", true),
              ($setup.formStatus === $setup.STATUS_REGISTER_CLIENT.InivitationSent)
                ? (_openBlock(), _createElementBlock("button", _hoisted_15, " Resend Invitation "))
                : _createCommentVNode("", true)
            ]))
      ])
    ])
  ]))
}